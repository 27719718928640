import React, {useEffect, useState} from 'react';
import {Button, Collapse, Form, InputNumber, Modal, Table} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import toast from 'react-hot-toast'
import api from "../../service/api";
import config from "../../config/config";
import Loading from "../../lib/loading";
import dayjs from "dayjs";
import {CloudDownloadOutlined} from "@ant-design/icons";



const StudentViewTask = () => {
    const [prewiew, setPrewiew] = useState([])
    const {user} = useSelector(state => state.auth)
    const [isOpen, setIsOpen] = useState(false)
    const [temporaryData, setTemporaryData] = useState({});
    const {id} = useParams()
    const [loading,setLoading] = useState(false)
    const [form] = Form.useForm();
    const navigate=useNavigate()



    const showModal = (data) => {
        console.log(data)
        setTemporaryData(data)
        setIsOpen(prevState => !prevState)
    }
    const onCancel = () => {
        setIsOpen(prevState => !prevState)
        form.resetFields()
    }

    const getTask = () => {
        setLoading(true)
        api.get(`teacher_task_list/students/?teacher_id=${user?.employee_id_number}&task_id=${id}`).then((res) => {
            console.log(res)
            setPrewiew(res.data.results)
        }).catch((err)=>{

        }).finally(()=>{
            setLoading(false)
        })
    }

    const download = (file) => {
        api.get(config.REACT_MEDIA_URL + file, {responseType: 'blob'}) // Set responseType to 'blob'
            .then((res) => {
                handleDownloadResponse(res, file)
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const handleDownloadResponse = (response, file) => {
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const filename = file.split('/').pop();
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    }
    const columns = [
        {
            title: 'F.I.SH',
            dataIndex: 'name',
            render:(_,record)=>(
                <>{record?.task_student_id?.first_name} {record?.task_student_id?.second_name} {record?.task_student_id?.third_name}</>
            )
        },
        {
            title: 'Guruhi',
            dataIndex: 'name',
            render:(_,record)=>(
                <>{record?.task_student_id.group.name}</>
            )
        },

        {
            title: 'Fakulteti',
            dataIndex: 'name',
            render:(_,record)=>(
                <>{record?.task_student_id.faculty.name}</>
            )
        },
        {
            title: 'Tili',
            dataIndex: 'name',
            render:(_,record)=>(
                <>{record?.task_student_id.group.educationLang.name}</>
            )
        },
        {
            title: 'Max. ball',
            dataIndex: 'total_score',
            width: '5%',
            render:(_,record)=>(
                <p className='font-bold'>{record?.total_score} ball</p>
            )
        },

        {
            title: 'Baholash',
            dataIndex: 'name',
            width:'5%',
            render:(_,record)=>(
                <>
                    {record?.mark_status?
                        <Button disabled={record?.mark_status} >Baholangan</Button>
                        :
                        <Button disabled={!record?.uploading_file_status} onClick={()=>showModal(record)}>Baholash</Button>

                    }
               </>
            )
        },{
            title: 'Ball',
            dataIndex: 'name',
            render:(_,record)=>(
                <>
                    {record?.mark}
                </>
            )
        }
    ];


    const mark=(values)=>{
        api.post('/teacher_task/student/mark/',{
            ...values,
            teacher_id:user?.employee_id_number,
            task_id:id,
            student_id:temporaryData?.task_student_id.id,
            task_student_id:temporaryData.id,

        }).then((res)=>{
            toast.success('Talaba baholandi!')
            onCancel()
            getTask()
        }).catch((err)=>{
            console.log(err)
            toast.error(err.response.data.massage)
            onCancel()
        })
    }
    const [temp,setTemp]=useState()
    const expandedRowRender = (record) => {

        // eslint-disable-next-line array-callback-return
        prewiew?.map((item)=>{
            // eslint-disable-next-line array-callback-return
            item?.task_group_list.map((itemIN)=>{
                if(itemIN?.id===record?.id){
                    setTemp(itemIN)
                }
            })
        })
        const columns = [
            {
                title: 'Izoh',
                dataIndex: 'comment',
                key: 'comment',
            },
            {
                title: 'Yuklangan vaqt',
                dataIndex: 'file_date_sending',
                key: 'file_date_sending',
                width:'13%',
                render: (_,record) =>(
                    <p>{dayjs(record.file_date_sending).format("YYYY-MM-DD HH:ss")}</p>
                ),
            },
            {
                title: 'File',
                dataIndex: 'file_date_sending',
                key: 'file_date_sending',
                width:"5%",
                render: (_,record) =>(
                    <Button className='ant-btn-default-primary flex items-center' onClick={()=>download(record.student_file)} type='primary'><CloudDownloadOutlined /></Button>
                ),
            },

        ];

        const data = temp?.student_task_fayls.map((file) => ({
            key: file.id,
            number: file.comment,
            student_file: file.student_file,
            file_date_sending: file.file_date_sending,
            comment: file.comment,
        }));
        console.log(data)
        return <Table columns={columns} dataSource={data} pagination={false} />;
    };    useEffect(() => {
        getTask()
    }, [user])



    return (<div className='flex '>
        {loading?<><Loading/></>:<>
            <div className='w-full flex flex-col gap-2'>
                <Button onClick={()=>navigate(-1)}  type='primary' className=' w-fit bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Orqaga</Button>

                {prewiew?.map((item)=>(

                    <Collapse
                        key={item?.id}
                        defaultActiveKey={['1']}
                        size="middle"
                        items={[{
                            key: '1', label: <div className='flex justify-between'><p>{item.name}</p>
                            </div>,
                            children:<>
                                {
                                    <Table pagination={false}  scroll={{
                                        x: 900
                                    }} columns={columns}
                                           expandable={{
                                               expandedRowRender,
                                               rowExpandable: (record) =>
                                                   record?.uploading_file_status
                                           }}
                                            dataSource={item?.task_group_list}
                                           rowKey="id"
                                    />

                                }
                            </>

                        }]}

                    />
                ))

                }

            </div>
        </>

        }
        <Modal title={temporaryData?.task_student_id?.first_name +" "+ temporaryData?.task_student_id?.second_name + ' ni baholash'} footer={null} centered open={isOpen} onCancel={onCancel}>
            <Form form={form} layout={"vertical"} onFinish={mark}>
                <Form.Item  rules={[
                    {
                        required: true,
                        message:"Iltimos to'ldiring!"
                    },
                ]} name='mark' label='Ball'>
                    <InputNumber size={"large"} className='w-full'/>
                </Form.Item>
                <Form.Item rules={[
                    {
                        required: true,
                        message:"Iltimos to'ldiring!"
                    },
                ]} name='comment' label='Izoh'>
                    <TextArea rows={4}/>
                </Form.Item>
                <Form.Item name='comment' label=''>
                   <Button htmlType='submit' className='w-full' size={"large"}>Saqlash</Button>
                </Form.Item>
            </Form>
        </Modal>

    </div>);
};

export default StudentViewTask;