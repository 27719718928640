// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-card-head{
    height: 80px !important;
    background: #1677ff !important;
}
.ant-card-body{
    background: #f4f4f4;
}
.button-alian:hover{
    color: #28C76F !important;
    border: 1px solid #28C76F !important;
}`, "",{"version":3,"sources":["webpack://./src/components/subjects/subject-item/subject-item.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,8BAA8B;AAClC;AACA;IACI,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,oCAAoC;AACxC","sourcesContent":[".ant-card-head{\n    height: 80px !important;\n    background: #1677ff !important;\n}\n.ant-card-body{\n    background: #f4f4f4;\n}\n.button-alian:hover{\n    color: #28C76F !important;\n    border: 1px solid #28C76F !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
