import React, {useEffect, useRef, useState} from 'react';
import {Button, Collapse, Form, Modal, Progress} from "antd";
import api from "../../service/api";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {FaDownload, FaEye, FaFileAlt, FaFileVideo, FaRegTrashAlt} from "react-icons/fa";
import Dragger from "antd/es/upload/Dragger";
import Input from "antd/es/input/Input";
import {InboxOutlined} from "@ant-design/icons";
import toast from 'react-hot-toast'
import config from "../../config/config";
import Loading from "../../lib/loading";
import {FaPlus} from "react-icons/fa6";

const UploadResource = () => {
    const {id} = useParams()
    const {user} = useSelector(state => state.auth)
    const [videos, setVideos] = useState()
    const [files, setFiles] = useState()
    const [loading, setLoading] = useState(false)
    const [isOpenFileModal, setIsOpenFileModal] = useState(false)
    const [isOpenVideoModal, setIsOpenVideoModal] = useState(false)
    const [isOpenVideoModalPerview, setIsOpenVideoModalPerview] = useState(false)
    const [file, setFile] = useState(null)
    const [temporaryData, setTemporaryData] = useState({})
    const [progres, setProgres] = useState(0)
    const [fileForm] = Form.useForm();
    const videoRef = useRef(null);
    const [isAvailableVideo, setIsAvailableVideo] = useState(true)
    const navigate=useNavigate()

    const pauseVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };

    const getResurces = () => {
        setLoading(true)
        api.get(`/teacher_topic/view/?topic_id=${id}&teacher_id=${user?.employee_id_number}`).then((res) => {
            setVideos(res.data.result.topic_videos)
            setFiles(res.data.result.topic_files)
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })
    }
    const fileslist = (<>
        {files?.map((file) => (<div
            className='h-[40px] flex items-center justify-between cursor-pointer rounded px-3 hover:bg-gray-200'>
            <div className='flex gap-2 items-center'>
                <FaFileAlt/>
                {file.name}

            </div>
            <div className='flex items-center gap-2'>
                <Button onClick={() => download(file.file_file)}><FaDownload/></Button>
                <button
                    className='hover:text-red-500 hover:bg-white border border-red-500 text-white bg-red-500 p-1 rounded px-[15px] py-[6px] transition duration-300'
                    onClick={() => deleteItem(file, 'file')}><FaRegTrashAlt/></button>
            </div>
        </div>))}
    </>)
    const videoList = (<>
        {videos?.map((file) => (<div
            className='h-[40px] flex items-center justify-between cursor-pointer rounded px-3 hover:bg-gray-200'>
            <div className='flex gap-2 items-center'>
                <FaFileVideo/>
                {file.name}

            </div>
            <div className='flex items-center gap-2'>
                <Button onClick={() => showModalVideoPreview(file)}><FaEye/></Button>
                <button
                    className='hover:text-red-500 hover:bg-white border border-red-500 text-white bg-red-500 p-1 rounded px-[15px] py-[6px] transition duration-300'
                    onClick={() => deleteItem(file, 'video')}><FaRegTrashAlt/></button>
            </div>
        </div>))}
    </>)
    const createTitle = (values) => {
        const formData = new FormData();
        formData.append('name', values.name)
        formData.append('files', file)
        formData.append('topic_id', id)
        formData.append('teacher_id', user?.employee_id_number)


        api.post('/teacher_topic/add/file/', formData, {
            headers: {
                'Content-type': 'multipart/form-data'
            }, timeout: Number.MAX_SAFE_INTEGER, onUploadProgress: (progressEvent) => {
                setProgres(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        }).then(() => {
            getResurces()
            onCloseModalFile()
            fileForm.resetFields()
            toast.success(<div className='flex gap-2'><p className='font-bold'>{values.name}</p> qo'shildi</div>)
        }).catch((err) => {
            console.log(err)

        }).finally(() => {
            // Set loading to false after the request (regardless of success or failure)
            setProgres(0)

        });
    }
    const createVideo = (values) => {
        const formData = new FormData();
        formData.append('name', values.name)
        formData.append('video', file)
        formData.append('topic_id', id)
        formData.append('teacher_id', user?.employee_id_number)


        api.post('/teacher_topic/add/video/', formData, {
            headers: {
                'Content-type': 'multipart/form-data'
            }, timeout: Number.MAX_SAFE_INTEGER, onUploadProgress: (progressEvent) => {
                setProgres(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        }).then(() => {
            getResurces()
            onCloseModalVideo()
            fileForm.resetFields()
            toast.success(<div className='flex gap-2'><p className='font-bold'>{values.name}</p> qo'shildi</div>)
        }).catch((err) => {
            console.log(err)

        }).finally(() => {
            // Set loading to false after the request (regardless of success or failure)
            setProgres(0)

        });
    }
    const download = (file) => {
        api.get(config.REACT_MEDIA_URL + file, {responseType: 'blob'}) // Set responseType to 'blob'
            .then((res) => {
                handleDownloadResponse(res, file)
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const handleDownloadResponse = (response, file) => {
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    }
    const deleteItem = (itemId, method) => {
        api.delete(`/teacher_topic/delete/${method}/?pk=${itemId.id}&teacher_id=${user?.employee_id_number}&topic_id=${id}`).then(() => {
            toast.success(`${itemId.name} ${method} o'chirildi`)
            getResurces()
        })
    }
    const showModalFile = () => {
        setIsOpenFileModal(prevState => !prevState)
    }
    const onCloseModalFile = () => {
        fileForm.resetFields()

        setIsOpenFileModal(prevState => !prevState)
    }
    const showModalVideo = () => {
        setIsOpenVideoModal(prevState => !prevState)
    }
    const onCloseModalVideo = () => {
        fileForm.resetFields()
        setIsOpenVideoModal(prevState => !prevState)
    }
    const showModalVideoPreview = (file) => {
        setTemporaryData(file)
        console.log(file)
        setIsOpenVideoModalPerview(prevState => !prevState)
    }
    const onCloseModalVideoPreview = () => {
        pauseVideo()
        setIsOpenVideoModalPerview(prevState => !prevState)
    }

    const handleChange = (type,files) => {
        console.log(files)
        if (type === 'file') {
            if (!(files?.file?.size / 1024 / 1024 < 10)) {
                toast.error('Fayl hajmi 10mb oshishi mumkin emas')
                setIsAvailableVideo(false)
            } else {
                setIsAvailableVideo(true)
            }
        }else {
            if (!(files?.file?.size / 1024 / 1024 < 600)) {
                toast.error('Video fayl hajmi 600mb oshishi mumkin emas')
                setIsAvailableVideo(false)
            } else {
                setIsAvailableVideo(true)
            }
        }

        setFile(files.file)
    }

    useEffect(() => {
        getResurces()
    }, [user])
    return (<div>

        {loading ? <Loading/> : <>
            <Button onClick={()=>navigate(-1)}  type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Orqaga</Button>

            <Collapse
                collapsible="icon"
                defaultActiveKey={['1']}
                size="large"
                items={[{
                    key: '1', label: <div className='flex justify-between'><p>Yuklangan fayllar ({files?.length})</p><Button
                        onClick={showModalFile} className='flex items-center gap-2 '
                        size={"large"}><FaPlus/>Fayl yuklash</Button></div>, children: fileslist
                },]}
            />
            <Collapse
                collapsible="icon"
                defaultActiveKey={['1']}
                className='mt-5'
                size="large"
                items={[{
                    key: '1', label: <div className='flex justify-between'><p>Yuklangan videolar ({videos?.length})</p>
                        <Button
                            onClick={showModalVideo} className='flex items-center gap-2 '
                            size={"large"}><FaPlus />Video yuklash</Button></div>, children: videoList
                },]}
            />
        </>

        }
        <Modal centered open={isOpenFileModal} title='Fayl joylash' footer={null} onCancel={progres > 0 ? () => {
            console.log('uncloasable')
        } : onCloseModalFile}>
            <Form
                layout='vertical'
                name="basic"
                onFinish={createTitle}
                autoComplete="off"
                form={fileForm}
            >
                <Form.Item
                    label="Fayl"
                    name="file"
                    rules={[{
                        required: true, message: "Iltimos to'ldiring!",
                    },]}
                >
                    <Dragger
                        beforeUpload={() => false}
                        multiple={false}
                        maxCount={1}
                        onChange={(event)=>handleChange('file',event)}

                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Bosing yoki fayl tashlang.</p>
                        <p className="ant-upload-hint">
                            Maksimal fayl hajmi 10 mb
                        </p>
                    </Dragger>
                </Form.Item>

                <Form.Item rules={[{
                    required: true, message: "Iltimos to'ldiring!",
                },]} name='name' label="Nomi">
                    <Input size={"large"}/>
                </Form.Item>


                <Form.Item>
                    {progres > 0 ?<Progress percent={progres} status="active"/> : <></>}
                    <Button disabled={progres > 0||!isAvailableVideo} loading={loading} style={{width: '100%'}}
                            className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3 w-full'
                            type="primary" htmlType="submit"
                            size={"large"}>
                        Yuklash
                    </Button>
                </Form.Item>
            </Form>
        </Modal>


        <Modal centered open={isOpenVideoModal} title='Video joylash' footer={null} onCancel={progres > 0 ? () => {
            console.log('uncloasable')
        } : onCloseModalVideo}>

            <Form
                layout='vertical'
                name="basic"
                onFinish={createVideo}
                autoComplete="off"
                form={fileForm}
            >
                <Form.Item
                    label="Fayl"
                    name="file"
                    rules={[{
                        required: true, message: "Iltimos to'ldiring!",
                    },]}
                >
                    <Dragger
                        beforeUpload={() => false}
                        multiple={false}
                        maxCount={1}
                        onChange={(event)=>handleChange('video',event)}
                        accept={'video/*'}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Bosing yoki video tashlang.</p>
                        <p className="ant-upload-hint">
                            Maksimal video hajmi 600 mb
                        </p>
                    </Dragger>
                </Form.Item>

                <Form.Item rules={[{
                    required: true, message: "Iltimos to'ldiring!",
                },]} name='name' label="Nomi">
                    <Input size={"large"}/>
                </Form.Item>


                <Form.Item>
                    {progres > 0 ? <Progress percent={progres} status="active"/> : <></>}
                    <Button disabled={progres > 0 || !isAvailableVideo} loading={loading} style={{width: '100%'}}
                            className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3 w-full'
                            type="primary" htmlType="submit"
                            size={"large"}>
                        Yuklash
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
        <Modal footer={null} centered title={temporaryData?.name} open={isOpenVideoModalPerview}
               onCancel={onCloseModalVideoPreview}>
            <div>
                <video ref={videoRef} className='h-[300px] object-cover w-full'
                       src={"https://api-lms.tsue.uz/api/stream/?path="+ temporaryData.vide_file} controls/>

            </div>
        </Modal>
    </div>);
};

export default UploadResource;