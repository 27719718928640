import {createSlice} from '@reduxjs/toolkit'
import {setItem} from "../helpers/persistan-store";

const initialState = {
    isLoading: false,
    loggedIn: false,
    error: null,
    user: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signUserStart: state => {
            state.isLoading = true
        },
        signUserSuccess: (state, action) => {
            state.loggedIn = true
            state.isLoading = false
            state.user = action.payload.details

            setItem('user', JSON.stringify(action.payload))
        },
        setToken: (state, action) => {
            setItem("refresh",action.payload?.backend_token?.refresh)
            setItem('token', action.payload?.backend_token?.access)
        },
        signUserFailure: (state, action) => {
            state.isLoading = false
            state.error = action.payload
        },
        logoutUser: state => {
            state.user = null
            state.loggedIn = false
        },
    },
})

export const {signUserFailure, signUserStart, signUserSuccess, setToken, logoutUser} = authSlice.actions
export default authSlice.reducer
