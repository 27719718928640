import React from 'react';
import {Button, Card, Popover} from "antd";
import './subject-item.css'
import {useNavigate} from "react-router-dom";

const SubjectItem = ({subject}) => {
    const navigate = useNavigate()
    const title = (
        <div>
            <p className='text-1xl text-white'>{subject?.content_id?.subject_name}</p>
            <p className='text-[12px] text-white'>{subject?.content_id.subjectgroup} | {subject?.content_id?.credit} (kredit)</p>
        </div>
    )
    return (
        <Card
            size="large"
            title={title}
            style={{
                maxWidth: 450,
                width: '100%'
            }}
        >
            <div className='item flex items-center justify-between border-b-2 h-[50px]'>
                <p className='text-[15px] font-bold'>Til</p>
                <p className='bg-blue-600 text-white rounded-full px-2'>{subject?.group_lang?.name}</p>
            </div>
            <div className='item flex items-center justify-between border-b-2 h-[50px]'>

                <p className='text-[15px] font-bold '>Guruhlar</p>
                <Popover placement="top" content={<>
                    {subject?.group_by?.map((item) => (
                        <p className='font-bold' key={item}>{item?.name}</p>
                    ))

                    }
                </>
                }
                         >
                <p className='bg-blue-600 text-white rounded-full px-2 cursor-pointer'>{subject?.group_by?.length}</p>
            </Popover>

        </div>
    <div className='item flex items-center justify-between border-b-2 h-[50px]'>
        <p className='text-[15px] font-bold'>Mavzular </p>
        <p className='bg-blue-600 text-white rounded-full px-2'>{subject?.topic_count}</p>
    </div>
    <div className='item flex items-center justify-between border-b-2 h-[50px]'>
        <p className='text-[15px] font-bold'>Video darsliklar</p>
        <p className='bg-blue-600 text-white rounded-full px-2'>{subject?.video_count}</p>
    </div>
    <div className='item flex items-center justify-between border-b-2 h-[50px]'>
        <p className='text-[15px] font-bold'>Topshiriqlar </p>
        <p className='bg-blue-600 text-white rounded-full px-2'>{subject?.task_count}</p>
    </div>
            <Button className='bg-[#1677ff] w-full text-white font-bold mt-2' size="large" onClick={()=>navigate(`/subject/inner/${subject?.content_id?.id}/${subject?.id}`)}>Tahrirlash</Button>
    <Button className='bg-[#28C76F] w-full text-white font-bold mt-2 hover:bg-white  hover:text-[#28C76F] button-alian' size="large" onClick={()=>navigate(`/subject/lesson/${subject?.content_id?.id}/${subject?.id}`)}>Dars o'tish</Button>

</Card>
)
    ;
};

export default SubjectItem;