import React from 'react';
import {useSelector} from "react-redux";
import {Avatar, Button, Popover} from "antd";
import {IoExitOutline, IoPersonSharp} from "react-icons/io5";
import {useNavigate} from "react-router-dom";


const User = () => {
    const navigate = useNavigate()
    const {user} = useSelector(state => state.auth)
    const logout=()=>{
       localStorage.clear()
        window.open('https://hemis.tsue.uz/dashboard/logout')
       window.location.reload()

    }
    return (
        <Popover content={(<>
            <Button onClick={()=>navigate('/profile')} className='w-full mb-2 flex items-center gap-2'><IoPersonSharp /> Profile</Button>
            <Button onClick={logout} type='danger' className='w-full flex items-center gap-2 bg-red-500 text-white border-none hover:border-red-500 '> <IoExitOutline /> Chiqish</Button>
        </>)} title="Tizim" trigger="click">

        <div className='text-white flex items-center gap-2 h-[50px] w-fit justify-self-end'>


            <Avatar
                size="large"
                gap={"5px"}
                src={user?.picture}
            />
            <div className='flex flex-col gap-1 '>
                <p className='h-[12px]'>
                    {user?.name}
                </p>
                <p >
                    O'qituvchi
                </p>

            </div>


        </div>
        </Popover>
    );
};

export default User;