import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import {BrowserRouter} from "react-router-dom";
import RouterConfiguration from "./routerConfiguration";
import {Provider} from "react-redux";
import store from "./store";
import {Toaster} from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Toaster
                    toastOptions={{
                        position:'bottom-right',
                        className: '',
                        success: {
                            style: {
                                background: 'green',
                                color: 'white',
                            },
                        },
                        error: {
                            style: {
                                background: 'red',
                                color: 'white',
                            },
                        },
                        style: {
                            maxWidth: '800px',
                            display:'flex',
                            justifyContent:'flex-start'
                        },
                    }}
                />
                    <RouterConfiguration/>
                    </BrowserRouter>
                    </Provider>
                    </React.StrictMode>

                    );

