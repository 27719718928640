import axios from './api'
import {getItem} from "../helpers/persistan-store";

const AuthService = {
    async userLoginHemis() {
        const {data} = await axios.get('authorization', {})
        return data
    },
    async userLoginSystem(code) {
        console.log(code)
        const {data} = await axios.get(`callback/${code}`)
        return data
    },
    async getUser() {
        // const {data} = await axios.get('/user')
        return getItem('user')
    },
}

export default AuthService
