import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Modal, Table} from "antd";
import api from "../../service/api";
import {useSelector} from "react-redux";
import {format} from 'date-fns';
import {FaEdit, FaEye} from "react-icons/fa";
import {IoIosCloudDownload} from "react-icons/io";
import config from "../../config/config";

const Task = () => {
    const {id} = useParams()
    const {user} = useSelector(state => state.auth)
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [leftScore,setLeftScore]=useState({})
    const [temporaryData, setTemporaryData] = useState({
        start_date: Date.now(),
        end_date: Date.now()
    })
    const navigate = useNavigate()

    const columns = [
            {
                title: 'Nomi',
                dataIndex: 'name',
                key: 'name'

            },
            {
                title: 'Boshlanish vaqti',
                width: '8%',
                render: (_, record) => (
                    <p>{format(record.start_date, 'dd-MM-yyyy HH:mm')}</p>
                )

            },
            {
                title: 'Tugash vaqti',
                width: '8%',
                render: (_, record) => (
                    <p>{format(record.end_date, 'dd-MM-yyyy HH:mm')}</p>
                )

            },
            {
                title: 'Tafsilotlar',
                width: '5%',
                render: (_, record) => (
                    <Button className='w-full text-center flex items-center justify-center ant-btn-default-primary' onClick={() => showModal(record)}><FaEye/></Button>
                )

            },
        {
            title: 'Tahrirlash',
            width: '5%',
            render: (_, record) => (
                <Button className='flex items-center justify-center w-full ant-btn-default-primary' onClick={() => navigate(`/task/edit/${record.id}`)}> <FaEdit/></Button>
            )

        },


        ]
    ;
    const showModal = (data) => {
        console.log(data)
        setTemporaryData(data)
        setIsOpenModal(prevState => !prevState)
    }
    const onClose = () => {
        setIsOpenModal(prevState => !prevState)
    }
    const getTasks = () => {
        setLoading(true)
        api.get(`/teacher/task/view/?topic_id=${id}&teacher_id=${user?.employee_id_number}`).then((res) => {
            setTasks(res.data.results)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }
    const download = (file) => {
        api.get(config.REACT_MEDIA_URL + file, {responseType: 'blob'}) // Set responseType to 'blob'
            .then((res) => {
                handleDownloadResponse(res, file)
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const handleDownloadResponse = (response, file) => {
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const filename = 'topshiriq';
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    }
    const downloadFiles = (file) => {
        // eslint-disable-next-line array-callback-return
        file?.map((item) => {
            download(item.task_file)
        })
    }
    const getLeftScore = () => {
        api.get(`/teacher/topic/score/?teacher_id=${user?.employee_id_number}&topic_id=${id}`).then((res) => {
            console.log(res.data.results)
            setLeftScore(res.data.results)
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        getLeftScore()
        getTasks()
    }, [user])
    return (
        <div>
            <div className='flex  justify-between items-center'>
                <Button onClick={()=>navigate(-1)}  type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Orqaga</Button>

                <Button disabled={leftScore?.scorereamin===0} onClick={()=>navigate(`/task/create/${id}`)} className='bg-[#1890ff] w-fit text-white mb-3' size='large'>Topshiriq yaratish </Button>
            </div>
            <Table pagination={false} loading={loading} columns={columns} dataSource={tasks}/>
            {/* eslint-disable-next-line no-useless-concat */}
            <Modal open={isOpenModal} footer={null} centered onCancel={onClose}
                   title={temporaryData?.name +  ' tafsilotlari'}>
                <div className='flex items-center justify-between h-[50px] border-b-2'>
                    <p>Nomi</p>
                    <p>{temporaryData?.name}</p>
                </div>
                <div className='flex items-center justify-between h-[50px] border-b-2'>
                    <p>Izoh</p>
                    <p>{temporaryData?.comment}</p>
                </div>
                <div className='flex items-center justify-between h-[50px] border-b-2'>
                    <p>Max. ball</p>
                    <p>{temporaryData?.score}</p>
                </div>
                <div className='flex items-center justify-between h-[50px] border-b-2'>
                    <p>Urinishlar</p>
                    <p>{temporaryData?.attempts}</p>
                </div>
                <div className='flex items-center justify-between h-[50px] border-b-2'>
                    <p>Boshlanish vaqti</p>
                    <p>{format(temporaryData?.start_date, 'dd-MM-yyyy HH:ss')}</p>
                </div>
                <div className='flex items-center justify-between h-[50px] border-b-2'>
                    <p>Tugash vaqti</p>
                    <p>{format(temporaryData?.end_date, 'dd-MM-yyyy HH:ss')}</p>
                </div>
                <div className='flex items-center justify-between h-[50px] border-b-2'>
                    <p>Yuklangan filelar</p>
                    <Button onClick={() => downloadFiles(temporaryData.task_files)}><IoIosCloudDownload/></Button>
                </div>
            </Modal>
        </div>
    );
};

export default Task;