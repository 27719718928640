import React from 'react';

const Loading = () => {
    return (
        <div className='w-full h-screen flex items-center justify-center text-2xl'>
            Yuklanmoqda ...
        </div>
    );
};

export default Loading;