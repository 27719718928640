import React, {useEffect, useState} from 'react';
import SubjectItem from "./subject-item/subject-item";
import api from "../../service/api";
import {useSelector} from "react-redux";
import Loading from "../../lib/loading";

const Subjects = () => {
    const [subjects, setSubjects] = useState([])
    const {user} = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false)

    const getSubjects = async () => {
        setLoading(true)
        await api.post('/teacher_contents/', {
            teacher_id: user?.employee_id_number
        }).then((res) => {
            console.log(res)
            setSubjects(res.data.result)
        }).catch((err) => {

        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getSubjects()
    }, [user])
    return (
        <div className='flex gap-2 flex-wrap'>
            {loading ? <><Loading/></> : <>
                {subjects?.map((item) => (
                    <SubjectItem key={item.id} subject={item}/>
                ))
                }
            </>}

        </div>
    );
};

export default Subjects;