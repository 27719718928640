import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import tokenService from "../service/TokenService";
import {getItem} from "../helpers/persistan-store";

export const RequireAuth = ({children}) => {
    const location = useLocation();
    const isToken = tokenService(getItem('token'))
    if (!getItem('token')||isToken) {
        return <Navigate to='/login' state={{path: location.pathname}}/>;
    }
    return children;
};
