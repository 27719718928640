import axios from 'axios'
import {getItem} from "../helpers/persistan-store";


axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.interceptors.request.use(config => {
    const token = getItem('token')
    const authorization = token ? `Bearer ${token}` : ''
    config.headers.Authorization = authorization
    return config
})

export default axios
