import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, InputNumber, Modal, Table} from "antd";
import Input from "antd/es/input/Input";
import TextArea from "antd/es/input/TextArea";
import {InboxOutlined} from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import api from "../../../service/api";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import toast from 'react-hot-toast'
import {FaRegFilePdf} from "react-icons/fa6";
import {
    BsFiletypeDocx,
    BsFiletypeJpg,
    BsFiletypePng,
    BsFiletypePpt,
    BsFiletypePptx,
    BsFiletypeSvg,
    BsFiletypeTxt,
    BsFiletypeXlsx
} from "react-icons/bs";
import {PiFileXls} from "react-icons/pi";
import {TbFileTypeZip} from "react-icons/tb";
import {SiJpeg} from "react-icons/si";
import {DiWebplatform} from "react-icons/di";
import {AiOutlineFileUnknown} from "react-icons/ai";

const CreateTask = () => {
    const [prewiew, setPrewiew] = useState()
    const {user} = useSelector(state => state.auth)
    const navigate = useNavigate()
    const [leftScore, setLeftScore] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [deleteFileList, setDeleteFileList] = useState()
    const [isOpenFileModal, setIsOpenFileModal] = useState(false)
    const [fileList, setFileList] = useState([]);
    const {id} = useParams()
    const formatDateString = (dateString) => {
        if (dateString) {
            let date = new Date(dateString);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            let time = date.toTimeString().split(' ')[0];
            return `${year}-${month}-${day} ${time}`;
        }

    }
    const handleChange = (info) => {
        let files1 = [...info.fileList];
        const fileSizeLimit = 10 * 1024 * 1024;
        files1 = files1.filter(file => file.size <= fileSizeLimit);
        setFileList(files1);
        let temp = []
        if (!(info?.file?.size / 1024 / 1024 < 10)) {
            toast.error('File hajmi 10mb oshishi mumkin emas')
        } else {
            // eslint-disable-next-line array-callback-return
            info?.fileList?.map((file) => {
                temp.push({...file, key: file.uid})
            })
            setPrewiew({
                ...prewiew, files: temp
            })
        }
    }
    const deleteFile = (filesToDelete) => {
        const updatedFiles = prewiew?.files.filter(file => !filesToDelete.find(deleteFile => deleteFile.uid === file.uid));
        setPrewiew(prevState => ({
            ...prevState,
            files: updatedFiles
        }));

        setDeleteFileList(null)
        setFileList(updatedFiles)
    }
    const onClose = () => {
        setIsOpenFileModal(prevState => !prevState)
    }

    const createTask = (values) => {
        console.log(values)
        setIsLoading(true)
        const formData = new FormData();
        formData.append('name', values.name)
        formData.append('comment', values.comment)
        formData.append('score', values.score)
        formData.append('topic_id_task', id)
        formData.append('teacher_id', user?.employee_id_number)
        formData.append('start_date', formatDateString(values.start_date))
        formData.append('end_date', formatDateString(values.end_date))
        formData.append('attempts', values.attampts)
        for (let i = 0; i < prewiew?.files.length; i++) {
            formData.append(`task_files`, prewiew?.files[i]?.originFileObj);
        }
        api.post('/teacher/task/fcreate/', formData, {
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }).then((res) => {
            console.log(res)
            navigate(`/task/edit/${res.data.results.id}`)
        }).catch((err) => {
            toast.error(err.response.data.message.message[0])
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const getLeftScore = () => {
        api.get(`/teacher/topic/score/?teacher_id=${user?.employee_id_number}&topic_id=${id}`).then((res) => {
            console.log(res.data.results)
            setLeftScore(res.data.results)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        console.log(prewiew)
        if (user) {
            getLeftScore()
        }
    }, [user]);


    const FileExtensionDetector = (filename) => {
        const exteionDetail = {
            pdf: {
                name: 'PDF document', icon: <FaRegFilePdf size={20}/>
            }, doc: {
                name: 'Word document 2007', icon: <BsFiletypeDocx size={20}/>
            }, docx: {
                name: 'Word document 2010 ', icon: <BsFiletypeDocx size={20}/>
            }, xls: {
                name: 'Excel document 2007', icon: <PiFileXls size={20}/>
            }, xlsx: {
                name: 'Excel document 2010', icon: <BsFiletypeXlsx size={20}/>
            }, ppt: {
                name: 'Powerpoint document 2007', icon: <BsFiletypePpt size={20}/>
            }, pptx: {
                name: 'Powerpoint document 2010', icon: <BsFiletypePptx size={20}/>
            }, zip: {
                name: 'Zip document', icon: <TbFileTypeZip size={20}/>
            }, rar: {
                name: 'Rar document', icon: <TbFileTypeZip size={20}/>
            }, txt: {
                name: 'Text document', icon: <BsFiletypeTxt size={20}/>
            }, jpeg: {
                name: 'Image document', icon: <SiJpeg size={20}/>
            }, jpg: {
                name: 'Image document', icon: <BsFiletypeJpg size={20}/>
            }, png: {
                name: 'Image document', icon: <BsFiletypePng size={20}/>
            }, webp: {
                name: 'Image document', icon: <DiWebplatform size={20}/>
            }, svg: {
                name: 'Image document', icon: <BsFiletypeSvg size={20}/>
            }, unknown: {
                name: 'Unknown document', icon: <AiOutlineFileUnknown size={20}/>
            }

        }
        const parts = filename.split('.');
        const extension = parts[parts.length - 1];
        if (exteionDetail[extension]) {
            return exteionDetail[extension]
        } else {
            return exteionDetail['unknown']
        }
    }
    const columns = [{
        title: 'Nomi', key: 'name', render: (_, record) => <>
            <p>{record.name}</p></>,
    }, {
        title: 'Hajmi', key: 'age', render: (_, record) => <p>{(record.size / 1024 / 1024).toFixed(2)} MB</p>,
    }, {
        title: 'Turi', key: 'address', render: (_, record) => <div className='flex items-center gap-1'>
            <div>{FileExtensionDetector(record.name)?.icon}</div>
            <div>{FileExtensionDetector(record.name)?.name}</div>
        </div>,
    },];
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setDeleteFileList(selectedRows)
        }
    };
    const showModalFilAdd = () => {
        setIsOpenFileModal(prevState => !prevState)
    }

    return (<>
        <div className='flex gap-5'>

            <div className='w-full'>
                <Button onClick={()=>navigate(-1)}  type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Orqaga</Button>

                <Form
                    onFinish={createTask}
                    layout="vertical"
                    style={{
                        width: '100%',
                    }}
                >
                    <Form.Item label="Nomi" name='name' rules={[{
                        required: true, message: "Iltimos to'ldiring!",
                    },]}>
                        <Input size='large' placeholder='Topshiriq nomi'/>
                    </Form.Item>
                    <Form.Item label="Izoh" name='comment' rules={[{
                        required: true, message: "Iltimos to'ldiring!",
                    },]}>
                        <TextArea rows={4}/>
                    </Form.Item>
                    <div className='flex gap-1'>

                        {leftScore.scorereamin ?
                            <Form.Item className='w-full'
                                       label={"Ajratilgan ball (" + leftScore?.scorereamin + "/" + leftScore?.score_jn + ")"}
                                       name='score'
                                       rules={[
                                           {required: true, message: "Iltimos to'ldiring!"},
                                           {type: 'number', message: 'Faqat raqam kiriting!'},
                                           {
                                               validator: (_, value) => {
                                                   if (value > leftScore?.scorereamin) {
                                                       return Promise.reject(`Ajratilgan bal ${leftScore?.scorereamin} dan katta bo'lishi mumkin emas`);
                                                   } else if (value === 0) {
                                                       return Promise.reject(`Ajratilgan bal 0 bo'lishi mumkin emas`);
                                                   } else if (value < 0) {
                                                       return Promise.reject(`Ajratilgan bal 0 dan kichik bo'lishi mumkin emas`);
                                                   }
                                                   return Promise.resolve();
                                               },
                                           },
                                       ]}>
                                <InputNumber placeholder="Ajratilgan ball" size="large" className='w-full'/>
                            </Form.Item> : <></>

                        }

                        <Form.Item className='w-full' label="Urunishlar" name="attampts" rules={[{
                            required: true, message: "Iltimos to'ldiring!",
                        },]}>
                            <InputNumber placeholder="Urunishlar" size="large" className='w-full'/>
                        </Form.Item>
                        <Form.Item className='w-full' label="Boshlanish vaqti" name='start_date' rules={[{
                            required: true, message: "Iltimos to'ldiring!",
                        },]}>
                            <DatePicker placeholder={'Boshlanish vaqti'} size='large' className='w-full'  showTime needConfirm={false}/>
                        </Form.Item>
                        <Form.Item className='w-full' label="Tugash vaqti" name='end_date' rules={[{
                            required: true, message: "Iltimos to'ldiring!",
                        },]}>
                            <DatePicker placeholder={'Tugash vaqti'} size='large' className='w-full'  showTime needConfirm={false}/>
                        </Form.Item>


                    </div>
                    <Form.Item label="Yuklangan fayllar ro'yxati" name='files' valuePropName="fileList">
                        <Button danger className='ant-btn-default-red disabled:text-red-500 mr-3'
                                disabled={deleteFileList?.length === 0 || !deleteFileList || deleteFileList === null}
                                onClick={() => deleteFile(deleteFileList)}>Delete</Button>
                        <Button className='ant-btn-default-primary' onClick={showModalFilAdd}>Qo'shish</Button>
                        <Table className='mt-2' rowSelection={{type: "checkbox", ...rowSelection,}}
                               dataSource={prewiew?.files} columns={columns} pagination={false}/>
                    </Form.Item>

                    <Form.Item className='w-full flex justify-end'>

                        <Button className='ant-btn-default-primary flex justify-self-end'
                                disabled={!prewiew?.files || prewiew?.files.length === 0} loading={isLoading}
                                htmlType={"submit"}
                                size={"large"}>Saqlash</Button>
                    </Form.Item>
                </Form>
                <Modal centered title={"Fayl qo'shish"} open={isOpenFileModal} footer={null}
                       onCancel={onClose}>
                    <Dragger
                        fileList={fileList}
                        className='w-full'
                        beforeUpload={() => false}
                        onChange={handleChange}

                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Bosing yoki fayl tashlang</p>
                        <p className="ant-upload-hint">
                            Maksimal fayl kattaligi 10 mb
                        </p>
                    </Dragger>
                    <Button className='w-full ant-btn-default-primary mt-2 ' size={"large"}
                            onClick={onClose}>Tayyor</Button>
                </Modal>
            </div>
        </div>
    </>);
};

export default CreateTask;