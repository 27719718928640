import React, {useEffect, useState} from 'react';
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setToken, signUserFailure, signUserStart, signUserSuccess} from "../../slice/auth";
import AuthService from "../../service/auth";

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading,setLoading]= useState(false)
    const loginHandler = async () => {
        localStorage.clear()
        dispatch(signUserStart())
        try {
            const response = await AuthService.userLoginHemis()
            window.location.href=response.authorization_url
        } catch (error) {
            dispatch(signUserFailure(error.response.data.errors))
        }
    }
    const loginToSystem = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            setLoading(true)
            try {
                const response = await AuthService.userLoginSystem(code)
                dispatch(signUserSuccess(response))
                dispatch(setToken(response))
                navigate('/')
            } catch (error) {
                setLoading(false)
                dispatch(signUserFailure(error.response.data.errors))
            }
        }
    }
    useEffect( () => {
       loginToSystem()
    }, [])
    return (
        <div className='flex w-full h-screen bg-cover' style={{backgroundImage:'url(./img_1.png)'}}>
            <div className='w-[50%] flex items-center justify-center flex-col'>
                <p className='font-bold text-4xl'>Toshkent davlat
                    iqtisodiyot universiteting </p>
                <p className='font-bold text-2xl'>masofaviy ta'lim platformasi..</p>
                <Button  loading={loading} size='large' className='w-[35%] mt-5 ant-btn-default-primary' type='primary'  onClick={loginHandler}>Kirish</Button>
            </div>
            <div className='w-[50%] flex items-center justify-center'>
                <img src='/logo.png' width={200} alt='test'/>
            </div>
        </div>
    );
};

export default Login;