import React, {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import Login from "./components/login/login";
import App from "./components/layout/App";
import {RequireAuth} from "./lib/RequiredAuth";
import Subjects from "./components/subjects/subjects";
import {getItem} from "./helpers/persistan-store";
import AuthService from "./service/auth";
import {useDispatch} from "react-redux";
import {signUserSuccess} from "./slice/auth";
import SubjectInnerView from "./components/subject-inner-view/subject-inner-view";
import UploadResource from "./components/upload-resources/upload-resource";
import Task from "./components/task/task";
import CreateTask from "./components/task/create-task/create-task";
import EditTask from "./components/task/edit-task/edit-task";
import SubjectLessonInner from "./components/subject-lesson-inner/subject-lesson-inner";
import TaskForMark from "./components/task-for-mark/task-for-mark";
import StudentViewTask from "./components/student-view-task/student-view-task";
import Profile from "./components/Profile/Profile";
const RouterConfiguration = () => {
    const dispatch = useDispatch()

    const getUser = async () => {
        try {
            const response = await AuthService.getUser()
            dispatch(signUserSuccess(JSON.parse(response)))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const token = getItem('token')
        if (token) {
            getUser()
        }
    }, [])
    return (
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/" element={<RequireAuth><App/></RequireAuth>}>
                    <Route path='/' element={<Subjects/>}/>
                    <Route path='/profile' element={<Profile/>}/>
                    <Route path='/subject/inner/:first_id/:second_id' element={<SubjectInnerView/>}/>
                    <Route path='/subject/lesson/:first_id/:second_id' element={<SubjectLessonInner/>}/>
                    <Route path='/upload/resources/:id' element={<UploadResource/>}/>
                    <Route path='/task/view/:id' element={<Task/>}/>
                    <Route path='/task/view/for/mark/:id' element={<TaskForMark/>}/>
                    <Route path='/task/create/:id' element={<CreateTask/>}/>
                    <Route path='/task/edit/:id' element={<EditTask/>}/>
                    <Route path='/student/view/for/task/:id' element={<StudentViewTask/>}/>
                </Route>

            </Routes>

    );
};

export default RouterConfiguration;