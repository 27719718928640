// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active{
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

}
.active.true{

        background: #28C76F ;
        animation: pulse-animation 2s infinite;

}
@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/subject-lesson-inner/subject.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,+BAA+B;IAC/B,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;;AAElB;AACA;;QAEQ,oBAAoB;QACpB,sCAAsC;;AAE9C;AACA;IACI;QACI,wCAAwC;IAC5C;IACA;QACI,uCAAuC;IAC3C;AACJ","sourcesContent":[".active{\n    width: 20px;\n    height: 20px;\n    background: rgba(0, 0, 0, 0.12);\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 0 auto;\n\n}\n.active.true{\n\n        background: #28C76F ;\n        animation: pulse-animation 2s infinite;\n\n}\n@keyframes pulse-animation {\n    0% {\n        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);\n    }\n    100% {\n        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
