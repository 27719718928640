import React from 'react';
import {Layout, theme} from 'antd';
import {Outlet} from "react-router-dom";
import User from "../user/user";

const {Header, Content, Footer} = Layout;
const App = () => {
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();

    return (
        <Layout>
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
                className='flex items-center justify-end'
            >
            <User/>
            </Header>
            <Content
                style={{
                    padding: '5px',
                }}
            >
                <div
                    style={{
                        background: colorBgContainer,
                        minHeight: window.innerHeight - (window.outerHeight - window.innerHeight),
                        padding: 24,
                        borderRadius: borderRadiusLG,
                        height: '100%'
                    }}
                >

                    <Outlet/>
                </div>
            </Content>
            <Footer
                style={{
                    textAlign: 'center',
                }}
            >
                © Smart Software - {new Date().getFullYear()}
            </Footer>
        </Layout>
    );
};
export default App;