import React, {useEffect, useState} from 'react';
import {Button, Collapse, Divider, Drawer, Form, Modal, Table, Tag} from "antd";
import api from "../../service/api";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {FaTrash} from "react-icons/fa";
import Input from "antd/es/input/Input";
import toast from 'react-hot-toast'
import {IoEnterOutline} from "react-icons/io5";
import './subject.css'
import {FaPlus} from "react-icons/fa6";

const Potocs = () => {
    const {user} = useSelector(state => state.auth)
    const [subjects, setSubjects] = useState([])
    const [formEdit] = Form.useForm();
    const [temporaryData, setTemporaryData] = useState({})
    const [isOpenBBB2, setIsOpenBBB2] = useState(false)
    const [isOpenBBB, setIsOpenBBB] = useState(false)
    const [roomName, setRoomName] = useState('')
    const navigate = useNavigate()
    const [contents,setContents] = useState([])
    const[selected,setSelected] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);


    const columns = [

        {
            title: 'Fan nomi',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
              <div>
                  {record?.content_id?.subject_id?.name}
              </div>
          )
          },
          {
            title: "O'quv reja",
            dataIndex: 'age',
            key: 'age',
            render: (_, record) => (
              <div>
                  {record?.content_id?.curriculum_id?.name}
              </div>
            )
          },
          {
            title: 'Guruhlar',
            dataIndex: 'address',
            key: 'address',
            render: (_, record) => (
              <div>
                  {record?.group_by?.map((item,i)=>{
                      return <Tag color="blue">{item.name}</Tag>
                  })}
              </div>
            )
          },
          {
              title: 'Guruh tili',
              dataIndex: 'address',
              key: 'address',
              render: (_, record) => (
                  <div>
                      {record?.group_lang?.name}
                  </div>
                )
            },
        ]
    ;
    const getSubjects = async () => {
        await api.get("/lessonroom/").then((res) => {
            console.log(res)
            setSubjects(res.data.results)
        }).catch((err) => {

        })
    }
    const enterBBB = (id) => {
        api.get(`/teacher/bigbluebutton/check_session/?bbb_id=${id}`).then(() => {
            join(id)
        }).catch(() => {
            restart(id)
        })
    }
    const restart = (id) => {
        api.get(`/teacher/bibluebutton/check_session/restart/?bbb_id=${id}&teacher_id=${user?.employee_id_number}`).then(() => {
            join(id)
        }).catch(() => {

        })
    }
    const join = (id) => {
        api.post(`/bigbluebutton/join/moderator/`, {
            username: user?.name,
            id: id
        }).then((res) => {
            window.open(res.data.url)
        }).catch(() => {
            toast.success('Dars xona mavjud emas')
        })
    }


    const showModalBBB = (id) => {
        setTemporaryData(id)
        setIsOpenBBB2(prevState => !prevState)
    }
    const closeModalBBB = () => {
        setIsOpenBBB(false)
    }

    const createBBB = (values) => {
        api.post(`/lessonroom/bbb/create/`, {
            lesson_room:temporaryData,
            name: values.name,
            maxParticipants: values.duration,
        }).then(() => {
            toast.success('Dars xona yaratildi')
            setIsOpenBBB2(false)
            getSubjects()
        }).catch(() => {
            toast.error('Xatolik yuz berdi')
        })
    }
    const getContents=()=>{
        api.get(`/content_teacher/teacher/list/`).then((res) => {
            const temporaryData = []
            res?.data?.results?.map((item)=>{
                temporaryData.push({
                    key: item.id,
                    ...item
                })
            })
            setContents(temporaryData)
        }).catch((err) => {
            toast.error('Xatolik yuz berdi')
        })
    }
    const createPotocs=()=>{
    api.post(`/lessonroom/create/`,{

        name:roomName,
        connect:selected
    }).then((res)=>{
        toast.success('Potok yaratildi')
        setSelectedRowKeys([])
        setContents([])
        getSubjects()
        closeModalBBB()
        setSelected([])
        setRoomName('')

    }).catch((err)=>{
        toast.error('Xatolik yuz berdi')
    })
    }
    const rowSelection = {
        selectedRowKeys, // keeping track of selected rows
        onChange: (newSelectedRowKeys, selectedRows) => {
          setSelected(newSelectedRowKeys);
          setSelectedRowKeys(newSelectedRowKeys);
        }

      };
    useEffect(() => {
        getContents()
    }, [])
    useEffect(() => {
        getSubjects()
    }, [user])
    const columnsDrawer = [
        {
          title: 'Fan nomi',
          dataIndex: 'name',
          key: 'name',
          render: (_, record) => (
            <div>
                {record?.content_id?.subject_id?.name}
            </div>
        )
        },
        {
          title: "O'quv reja",
          dataIndex: 'age',
          key: 'age',
          render: (_, record) => (
            <div>
                {record?.content_id?.curriculum_id?.name}
            </div>
          )
        },
        {
          title: 'Guruhlar',
          dataIndex: 'address',
          key: 'address',
          render: (_, record) => (
            <div>
                {record?.group_by?.map((item,i)=>{
                    return <Tag color="blue">{item.name}</Tag>
                })}
            </div>
          )
        },
        {
            title: 'Guruh tili',
            dataIndex: 'address',
            key: 'address',
            render: (_, record) => (
                <div>
                    {record?.group_lang?.name}
                </div>
              )
          },
      ];

      const deleteItem = (record) =>{
        api.delete(`/lessonroom/get/${record}`).then(() => {
            toast.success('Dars xona o\'chirildi')
            getSubjects()
        }).catch((err) => {
            toast.error('Xatolik yuz berdi')
        })
      }

    return (
        <div>
            <div className='flex justify-between items-center w-full'>
            <Button onClick={()=>navigate('/')}  type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Orqaga</Button>
            <Button onClick={()=>{
                getContents()
                setIsOpenBBB(true)
                }}  type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Dars xona yaratish</Button>
                </div>
          
                {subjects?.map((item)=>(

<Collapse
className='mb-2'
    key={item?.id}
    defaultActiveKey={['0']}
    size="middle"
    items={[{
        key: '1', label: <div className='flex justify-between'><p>{item.name}</p>
        <div className='flex gap-2'>
          {item.team_bigbluebutton ?
                            <Button disabled={!item.is_active} onClick={() => enterBBB(item.team_bigbluebutton)} className={item.is_active?"bg-[#28C76F]  w-fit text-white font-bold  hover:bg-white  hover:text-[#28C76F] button-alian flex items-center justify-center gap-2":" flex items-center justify-center gap-2"} size={"medium"}>
                                <IoEnterOutline/> Dars xonaga kirish </Button>
                            :
                            <Button disabled={!item.is_active} className={item.is_active?"bg-[#28C76F] w-fit text-white font-bold  hover:bg-white  hover:text-[#28C76F] button-alian flex items-center justify-center gap-2":" flex items-center justify-center gap-2"}
                                    size={"md"} onClick={() => showModalBBB(item?.id)}> <FaPlus />
                                Dars xona
                                yaratish </Button>

                        }

                        <Button type='danger' onClick={()=>deleteItem(item?.id)} className='bg-red-700 text-white'><FaTrash/></Button>
                        </div>
        </div>,
        children:<>
            {
                <Table pagination={false}  scroll={{
                    x: 900
                }} columns={columns}
                    dataSource={item?.connect}
                       rowKey="id"
                />

            }
        </>

    }]}

/>
))

}

            <Drawer width={'70%'} title="Dars xona yaratish"  open={isOpenBBB} onClose={closeModalBBB}>
            <div>
      
      <Divider />
      <Input size='large' className='w-1/2 mb-2' value={roomName} placeholder='Xona nomi' onChange={(e)=>setRoomName(e?.target?.value)} />
      <Table
      pagination={false}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columnsDrawer}
        dataSource={contents}
      />
      <Button onClick={createPotocs} disabled={selected?.length===0||roomName?.length===0} type='primary' className=' mt-3 bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Saqlash</Button>

    </div>
      </Drawer>
            <Modal title="Dars xona yaratish" centered open={isOpenBBB2} onCancel={()=>setIsOpenBBB2(false)} footer={null}>
                <Form
                    onFinish={createBBB}
                    form={formEdit}
                >
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos to'ldiring!",
                            },
                        ]}>
                        <Input placeholder='Xona nomi' size='large'/>
                    </Form.Item>
                    <Form.Item
                        name="duration"
                        rules={[
                            {
                                required: true,
                                message: "Iltimos to'ldiring!",
                            },
                        ]}>
                        <Input placeholder='Max. qatnashuvchilar soni' size='large'/>
                    </Form.Item>
                    <Button htmlType={"submit"} type='primary'
                            className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3 w-full'
                            size={"large"}>Yaratish</Button>
                </Form>
            </Modal>
        </div>
    );
};

export default Potocs;