import React, {useEffect, useState} from 'react';
import {Button, Collapse, DatePicker, Form, InputNumber, Modal, Switch, Table} from "antd";
import Input from "antd/es/input/Input";
import TextArea from "antd/es/input/TextArea";
import {InboxOutlined} from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import api from "../../../service/api";
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import dayjs from 'dayjs';
import {MdDeleteForever} from "react-icons/md";
import {IoIosCloudDownload} from "react-icons/io";
import toast from 'react-hot-toast'
import config from "../../../config/config";
import {FaPlus} from "react-icons/fa6";


const EditTask = () => {
    const [prewiew, setPrewiew] = useState()
    const {user} = useSelector(state => state.auth)
    const [form] = Form.useForm();
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenStudent, setIsOpenStudent] = useState(false)
    const [groups, setGroups] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [temporaryData, setTemporaryData] = useState({});
    const [students, setStudents] = useState([]);
    const [studentsS, setStudentsS] = useState([]);
    const [st, setSt] = useState('');
    const {id} = useParams()
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([]);
    const navigate = useNavigate()

    const formatDateString = (dateString) => {
        if (dateString) {
            let date = new Date(dateString);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            month = month < 10 ? '0' + month : month;
            day = day < 10 ? '0' + day : day;
            let time = date.toTimeString().split(' ')[0];
            return `${year}-${month}-${day} ${time}`;
        }

    }
    const handleChange = (info) => {
        let fileList = [...info.fileList];
        setFileList(fileList);
    };
    const createTask = (values) => {
        setLoading(true)
        const formData = new FormData();
        formData.append('name', values.name)
        formData.append('comment', values.comment)
        formData.append('score', values.score)
        formData.append('topic_id_task', prewiew?.topic_id_task?.id)
        if (fileList) {
            for (let i = 0; i < fileList?.length; i++) {
                formData.append(`task_files`, fileList[i]?.originFileObj);
            }
        }
        formData.append('teacher_id', user?.employee_id_number)
        formData.append('start_date', formatDateString(values.start_date))
        formData.append('end_date', formatDateString(values.end_date))
        api.patch(`/teacher/task/fupdate/${id}/`, formData, {
            headers: {
                'Content-type': 'multipart/form-data'
            }
        }).then((res) => {
            toast.success('Topshiriq muvoffaqiyatli o\'zgartirildi')
            setFileList([]);
            getTask()

        }).catch((err) => {
            toast.error(err?.response?.data?.message?.message ? err?.response?.data?.message?.message : "Xatolik yuz berdi")
        }).finally(() => {
            setLoading(false)
        })
    }
    const download = (file) => {
        api.get(config.REACT_MEDIA_URL + file, {responseType: 'blob'})
            .then((res) => {
                handleDownloadResponse(res, file)
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const handleDownloadResponse = (response, file) => {
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const filename = file.split('/').pop();
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
    }


    const showModal = () => {
        setIsOpen(prevState => !prevState)
    }
    const onCancel = () => {
        setSelectedRowKeys([])
        setIsOpen(prevState => !prevState)
    }
    const showModalStudent = (group) => {
        setTemporaryData(group)
        getStudent(group.id)
        setIsOpenStudent(prevState => !prevState)
    }
    const onCancelStudent = () => {
        setIsOpenStudent(prevState => !prevState)
        setSt('')
    }
    const getTask = () => {
        api.get(`/teacher/task/detail/${id}`).then((res) => {
            form.setFieldValue('name', res.data.results?.name)
            form.setFieldValue('comment', res.data.results?.comment)
            form.setFieldValue('score', res.data.results?.score)
            form.setFieldValue('attempts', res.data.results?.attempts)
            form.setFieldValue('start_date', dayjs(dayjs(res.data.results?.start_date).format('YYYY-MM-DD HH:mm:ss')))
            form.setFieldValue('end_date', dayjs(dayjs(res.data.results?.end_date).format('YYYY-MM-DD HH:mm:ss')))
            setPrewiew(res.data.results)
        })
    }
    const getGroups = () => {
        api.get(`/teacher/task/group/view?teacher_id=${user?.employee_id_number}&task_id=${id}`).then((res) => {
            let arr = []
            // eslint-disable-next-line array-callback-return
            res.data.results?.map((item) => {
                arr.push({
                    name: item.name,
                    educationLang: item?.educationLang?.name,
                    id: item.id,
                    key: item.id
                })

            })
            setGroups(arr)
        }).catch((err) => {

        })
    }
    const columns = [
        {
            title: 'Nomi',
            dataIndex: 'name',
        },
        {
            title: 'Til',
            dataIndex: 'educationLang',
        }

    ];
    const columnsStudent = [
        {
            title: 'Nomi',
            dataIndex: 'name',
            render: (_, record) => (
                <>
                    {record.task_student_id?.first_name} {record.task_student_id?.second_name} {record.task_student_id?.third_name}
                </>
            )
        },
        {
            title: 'Xolat',
            dataIndex: 'status',
            render: (_, record) => (
                <>
                    <Switch className='bg-gray-500' checked={record.status_control}
                            onChange={(e) => onChange(record, e)}/>
                </>
            )
        }

    ];
    const onChange = (record, checked) => {
        api.post(`teacher/task/${id}/${record.task_student_id.group.id}/student/status_change/${record.task_student_id
            .id}/`, {
            teacher_id: user?.employee_id_number,
            status: checked
        }).then(() => {
            const updatedArray = students.map(obj =>
                obj.task_student_id.id === record.task_student_id
                    .id ? { ...obj, status_control: checked } : obj
            );

            setStudents(updatedArray)
            getStudent(record.task_student_id.group.id)
            toast.success(record.task_student_id.first_name + " " + record.task_student_id.second_name + ` ning statusi ${checked ? 'yoqildi' : "o'chirildi"}`)
        }).catch((err) => {
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : 'Xatolik yuz berdi!')
        })
    }
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const addGroup = () => {
        api.post(`/teacher/task/${id}/group/add/`, {
            teacher_id: user?.employee_id_number,
            groups: selectedRowKeys
        }).then((res) => {
            toast.success("Guruh qo'shildi")
            getTask()
        }).catch((err) => {
            toast.error("Bu guruh allaqachon qo'shilgan")
        }).finally(() => {
            onCancel()
        })
    }
    const deleteFile = (idF) => {
        api.delete(`/teacher/task/${id}/${user?.employee_id_number}/delete_file/${idF.id}`,).then((res) => {
            toast.success("Fayl o'chirildi")
            getTask()
        }).catch((err) => {
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : 'Xatolik yuz berdi!')
        })
    }
    const deleteGroup = (idG) => {
        api.delete(`/teacher/task/${id}/${user?.employee_id_number}/group/${idG}/delete`,).then((res) => {
            toast.success("Guruh o'chirildi")
            getTask()
        }).catch((err) => {
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : 'Xatolik yuz berdi!')
        })
    }
    const getStudent = (Gid) => {
        api.get(`teacher/task/${id}/${Gid}/student_action/view/`).then((res) => {
            setStudents(res.data.results)
        }).catch((err) => {
            toast.error(err?.response?.data?.message ? err?.response?.data?.message : 'Xatolik yuz berdi!')
        })
    }
    useEffect(() => {
        if (user) {
            getTask()
            getGroups()
        }
    }, [user])
    const taskFiles = (
        <> {prewiew?.task_files.length !== 0 ? <>
            {prewiew?.task_files?.map((file, index) => (
                <div className='flex items-center justify-between h-[40px] last:border-b-0 border-b-2'>
                    <p>
                        {index + 1} - fayl
                    </p>
                    <div className='flex gap-1'>
                        <Button
                            onClick={() => deleteFile(file)}
                            className='ant-btn-default-red' danger>
                            <MdDeleteForever/></Button>
                        <Button className='ant-btn-default-primary'
                                onClick={() => download(file?.task_file)}><IoIosCloudDownload/></Button>
                    </div>
                </div>
            ))

            }
        </> : <div className='text-center opacity-60'>
            Fayl mavjud emas
        </div>

        }
        </>
    )
    const groupsList = (
        <>
            {prewiew?.groups.length > 0 ?
                <> {prewiew?.groups?.map((group) => (
                    <div className='flex items-center justify-between text-start h-[40px] border-b-2 last:border-b-0 '>
                        <p onClick={() => showModalStudent(group)}
                           className='w-[100px] text-blue-600 cursor-pointer hover:underline'>
                            {group?.name}
                        </p>
                       <div style={{textAlign:'start', width:'100px',display:'flex',alignItems:'center'}}>
                           {group?.educationLang}
                       </div>

                        <div className='flex gap-1'>
                            <Button
                                onClick={() => deleteGroup(group.id)}
                                className='ant-btn-default-red' danger>
                                <MdDeleteForever/></Button>

                        </div>
                    </div>
                ))

                }
                </> :
                <div className='text-center opacity-60'>
                    Guruh mavjud emas
                </div>
            }
        </>
    )
    const searchByName = (data, searchValue) => {
        setSt(searchValue)
        const results = [];
        for (const person of data) {
            const {first_name, second_name, third_name} = person.task_student_id;
            const fullName = `${second_name} ${first_name} ${third_name}`.toUpperCase();
            if (fullName.includes(searchValue.toUpperCase())) {
                results.push(person);
            }
        }
        setStudentsS(results)
    }
    return (
        <>
            <Button onClick={()=>navigate(-1)}  type='primary' className='bg-[#1677ff] flex items-center justify-center gap-2 mb-3' size='large'>Orqaga</Button>

        <div className='flex gap-5'>
        <div className='w-[50%]'>
            <Form
                form={form}
                onFinish={createTask}
                layout="vertical"
                style={{
                    width: '100%',
                }}
            >
                <Form.Item label="Nomi" name='name'>
                    <Input size='large' placeholder='Topshiriq nomi'/>
                </Form.Item>
                <Form.Item label="Izoh" name='comment'>
                    <TextArea rows={4}/>
                </Form.Item>
                <Form.Item className='w-full' label="Ajratilgan ball" name='score'>
                    <InputNumber size="large" className='w-full'/>
                </Form.Item>
                <Form.Item className='w-full' label="Urunishlar" name='attempts'>
                    <InputNumber size="large" className='w-full'/>
                </Form.Item>

                <Form.Item className='w-full' label="Boshlanish vaqti" name='start_date'>
                    <DatePicker disabled={!prewiew?.change_session} size='large' className='w-full' showTime
                                needConfirm={false}/>

                </Form.Item>

                <Form.Item className='w-full' label="Tugash vaqti" name='end_date'>
                    <DatePicker disabled={!prewiew?.change_session} size='large' className='w-full' showTime
                                needConfirm={false}/>

                </Form.Item>
                <Form.Item label="Fayllar" valuePropName="fileList">
                    <Dragger
                        fileList={fileList}
                        className='w-full'
                        beforeUpload={() => false}
                        onChange={handleChange}

                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined/>
                        </p>
                        <p className="ant-upload-text">Bosing yoki fayl tashlang</p>
                        <p className="ant-upload-hint">
                            Maksimal fayl hajmi 10 mb
                        </p>
                    </Dragger>
                </Form.Item>

                <Form.Item className='w-full flex justify-end'>
                    <Button loading={loading} className='ant-btn-default-primary' htmlType={"submit"}
                            size={"large"}>Saqlash</Button>
                </Form.Item>
            </Form>
        </div>
        <div className='w-[50%] flex flex-col gap-2'>
            <Collapse
                collapsible="icon"
                defaultActiveKey={['1']}
                size="middle"
                items={[{
                    key: '1', label: <div className='flex justify-between'><p>Topshiriq fayllari</p></div>,
                    children: taskFiles
                }]}

            />

            <Collapse
                collapsible="icon"
                defaultActiveKey={['1']}
                size="middle"
                items={[{
                    key: '1', label: <div className='flex justify-between'><p>Topshiriq guruhlari</p>
                        <Button onClick={showModal} style={{display:'flex',alignItems:'center',gap:"2px"}}><FaPlus /> Guruh qo'shish</Button>
                    </div>,
                    children: groupsList

                }]}

            />

        </div>
        <Modal title='Guruhlar' centered footer={null} onCancel={onCancel} open={isOpen}>
            <Table pagination={false} rowSelection={rowSelection} columns={columns} dataSource={groups}/>
            <Button onClick={addGroup} className='w-full mt-2' size={"large"}
                    disabled={selectedRowKeys.length <= 0}>Qo'shish</Button>
        </Modal>
        <Modal title={`${temporaryData?.name} talabalari`} centered footer={null} onCancel={onCancelStudent}
               open={isOpenStudent} style={{maxHeight: '80vh', overflowY: 'scroll'}}>
            <style>
                {`
            ::-webkit-scrollbar {
              display: none;
            }
          `}
            </style>
            <Input value={st} onChange={(e) => {
                searchByName(students, e.target.value)
            }} placeholder='Qidirish' size={"large"} className='mb-3'/>
            <Table pagination={false} columns={columnsStudent} dataSource={st.length > 0 ? studentsS : students}/>
        </Modal>
    </div>
        </>
            );
};

export default EditTask;